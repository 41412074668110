const files = require.context('./api', true, /\.js$/)

const routeAlls = [
    {
        name: 'home',
        path: '/',
        meta:{title:'首页'},
        component: () => import('@/views/index.vue'),
    },
    {
        name: 'agreement',
        path: "/agreement/:type/:title?",
        meta:{title:'用户协议'},
        component: () => import('@/views/agreement.vue'),
    },
]
//key是相对路径名
files.keys().forEach(key => {
    let routes = files(key).routes || [];
    for (let i = 0; i < routes.length; i++) {
        routeAlls.push(routes[i]);
    }
})

export const routes = [
    {
        name: '',
        path:'',
        component: () => import('@/views/api/pcLayout'),
        children: routeAlls.concat([
            {
                name: '',
                path: "*",
                component: {
                    template:'<exception :exception-data="exception"></exception>',
                    components:{
                        exception :() => import('@/components/exception')
                    },
                    data(){
                        return {exception:{title:'页面走丢了',status:404}};
                    }
                },
                meta: {
                    title: '页面走丢了'
                }
            },])
    }
]